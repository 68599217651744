import { CheckCircle, Close } from '@mui/icons-material';
import { Card, CardActions, IconButton, SxProps } from '@mui/material';
import { OptionsObject, SnackbarContent, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';
import { fontFamilies } from '../../utils';
import { Grid } from '../layout';
import './snackBar.scss'; // need additionally add class to snackbar provider to make it work

const cardStyle: SxProps = (theme: any) => ({
    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
    boxShadow: 'none',
    minWidth: '480px',
    maxWidth: '480px',
    padding: '16px 20px',
    [theme.breakpoints.down(480)]: {
        minWidth: 'auto'
    }
});

const messageStyle = {
    fontFamilies: fontFamilies.GraphikRegular,
    color: '#2E2E36',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '32px',
    paddingRight: '10px',
    maxWidth: '349px',
};

const CFRASnackComponent = forwardRef((props: any, ref: any) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref}>
            <Card sx={cardStyle}>
                <CardActions>
                    <Grid container sx={{ gap: '10px' }}>
                        <Grid item sx={{ paddingTop: '8px' }}>
                            <CheckCircle sx={{ fill: '#58A65C', fontSize: '17px' }} />
                        </Grid>
                        <Grid item sx={{ ...messageStyle }}>
                            {props.message}
                        </Grid>
                        <Grid item sx={{ flexGrow: 1 }}></Grid>
                        <Grid
                            item
                            sx={{
                                paddingTop: '2px',
                            }}>
                            <IconButton size='small' onClick={handleDismiss}>
                                <Close sx={{ fill: '#3078B5', fontSize: '18px' }} />
                            </IconButton>
                        </Grid>
                    </Grid>

                    {/* </Stack> */}
                </CardActions>
            </Card>
        </SnackbarContent>
    );
});

const SnackConfig = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    autoHideDuration: 8000,
    content: (key: SnackbarKey, message: SnackbarMessage) => <CFRASnackComponent id={key} message={message} />,
} as OptionsObject;

export function DefaultCFRASnack(enqueue: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) {
    return function (message: SnackbarMessage) {
        return enqueue(message, SnackConfig);
    };
}
