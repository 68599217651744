import HighchartsReact from 'highcharts-react-official';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export function useHighchartsWidth(
    chartRef: React.RefObject<HighchartsReact.RefObject> | undefined,
    reflowBeforeGetWidth = true,
) {
    const [chartWidth, setChartWidth] = useState(0);

    useEffect(() => {
        const _handleResize = () => {
            if (reflowBeforeGetWidth) {
                chartRef?.current?.chart.reflow();
            }

            if (chartRef?.current?.chart?.chartWidth) {
                setChartWidth(chartRef.current.chart.chartWidth);
            }
        };

        _handleResize();

        const handleResize = debounce(_handleResize, 500);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel();
        };
    }, [chartRef, chartRef?.current?.chart?.chartWidth, reflowBeforeGetWidth]);

    return chartWidth;
}
